import { useEffect } from 'react';
import { useRouter } from 'next/router';

import type { Page } from '@/types/page';

import { ROUTES } from '@/utils/vars';

import { LoadingScreen } from '@/components/loading/LoadingScreen';

const Custom404Page: Page = () => {
  const { push } = useRouter();

  useEffect(() => {
    push(ROUTES.profile);
  }, []);

  return <LoadingScreen visible />;
};

export default Custom404Page;
